export const environment = {
  production: true,

  //Endpoints
  apiLeagueServer: 'https://stgcompeteapi.fullswingapps.com/api',
  apiBaymanagerServer: 'https://stglauncher.fullswingapps.com/api/fs',
  apiBaymanagerPanel: 'https://stgbaymanager.fullswingapps.com',
  playerCompeteMobile: 'https://m.stgcompete.fullswingapps.com',
  authPortal: 'https://stgauth.fullswingapps.com',
  hostName: 'devbaymanager.fsgapi.net',

  //Variables
  defaultPage: 'home',
  navName: '_fs_nav_name',

  //Objects
  breakPointScreen: {
    small: 576,
    Medium: 768,
    large: 992,
    extraLarge: 1200,
    xExtraLarge: 1400,
  },

  //Ploicy groups
  companyAdmin: 'companyadmin',
  competeAdmin: 'competeadmin',
  bayManagerAdmin: 'baymanageradmin',
  competePlayer: 'competeplayer',
  adminPortal:'techadmin',
  residential: 'Users'
};
