const awsmobile = {
  aws_project_region: "us-west-2",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_QXkqgwCpu",
  aws_user_pools_web_client_id: "7i8tejba4a2s76bccgdnamssoc",
  oauth: {
    domain: "bay-manager.auth.us-west-2.amazoncognito.com",
    scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
    redirectSignIn: "https://stgcompete.fullswingapps.com/login",
    redirectSignOut: "https://stgcompete.fullswingapps.com/login",
    responseType: "code",
  },
};

export default awsmobile;
